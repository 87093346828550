
import DraggablePanel from '@/components/buttons/DraggablePanel.vue';
import ScrollableContainer from '@/components/common/ScrollableContainer.vue';
import Searchbar from '@/components/common/Searchbar.vue';
import StatusBadge from '@/components/common/StatusBadge.vue';
import RequestsCardList from '@/components/list/RequestsCardList.vue';
import AppName from '@/components/mixin/AppName.vue';
import Datatable from '@/components/table/Datatable.vue';
import router from '@/router';
import { FetchNewSites, FetchPendingSites } from '@/services/api/ProductApi';
import { trackEvent } from '@/services/Mixpanel';
import { Product } from '@/store/models/Product';
import ProductStore from '@/store/modules/ProductStore';
import User from '@/store/modules/User';
import { SelectedRoute } from '@/utils/classes/SelectedRoute';
import { goToSiteRequest } from '@/utils/FuelUtils';
import { sortRequestedSites } from '@/utils/SortingUtils';
import { GetVisibleTableColumnFormatters } from '@/utils/table-fields/TableFields';
import { AllowedField } from '@/utils/types/AllowedFields';
import { productType } from '@/utils/types/ProductType';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
@Component({
  components: {
    Datatable,
    RequestsCardList,
    Searchbar,
    StatusBadge,
    DraggablePanel,
    ScrollableContainer
  },
  methods: {
    goToSiteRequest
  }
})
export default class RequestsView extends mixins(AppName) {
  public isTable = true;
  public requestedSites: Product[] = [];
  public filteredRequestedSites: Product[] = [];
  public loading = true;
  public searchTerm = '';
  public activeSearch = false;
  public tableColumns: any[] = [];
  public statusList = ['pending', 'install', 'sign-off', 'live'];
  public statusFilter = ['pending', 'install', 'sign-off', 'live'];

  created() {
    this.getPendingSites();
    window.addEventListener('resize', () => {
      this.updateWidth();
    });
    window.addEventListener('mouseup', () => {
      this.updateWidth();
    });
    this.$root.$on('orgIndexChanged', () => {
      this.getPendingSites();
    });
  }

  mounted() {
    trackEvent('User viewing milk sites');
    this.updateWidth();
    GetVisibleTableColumnFormatters(
      [AllowedField.SITE, AllowedField.ADDRESS, AllowedField.ENTITY_STATUS],
      false
    ).then(columns => {
      this.tableColumns = columns;
    });
    ProductStore.fetchAllEntityStates(this.getAppName(false));
    new SelectedRoute(this.getAppName(false) as productType);
  }

  destroyed() {
    window.removeEventListener('resize', () => {
      this.updateWidth();
    });
    window.removeEventListener('mouseup', () => {
      this.updateWidth();
    });
  }

  @Watch('statusFilter')
  statusFilterUpdated() {
    trackEvent(`User applied filters to ${this.getAppName(false)} requests`, {
      statusFilter: JSON.stringify(this.statusFilter)
    });
    this.filterSites();
  }

  public updateWidth() {
    this.isTable = window.innerWidth > 1000;
  }

  private getPendingSites() {
    FetchPendingSites({
      partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0,
      productType: this.getAppName(false)
    }).then(sites => {
      FetchNewSites({
        partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0,
        productType: this.getAppName(false)
      }).then(newSites => {
        const allSites = sites.concat(newSites);
        this.requestedSites = sortRequestedSites(allSites);
        this.filteredRequestedSites = this.groupBySite(this.requestedSites);
        this.loading = false;
      });
    });
  }

  public groupBySite(allSites: any[]) {
    const map = new Map();
    allSites.forEach((item: any) => {
      const collection = map.get(item.productId);
      if (!collection) {
        map.set(item.productId, {
          ...item,
          entities: [
            {
              ...item,
              name: item.name,
              entityId: item.entityId,
              capacity: item.capacity,
              entityCustomFieldData: item.entityCustomFieldData,
              entityCustomSettingsData: item.entityCustomSettingsData,
              partnerDefinedEntityId: item.partnerDefinedEntityId,
              partnerDefinedEntityName: item.partnerDefinedEntityName,
              metrics: { fuelType: item.fuelType, feedType: item.feedType },
              status: item.entityStatus
            }
          ]
        });
      } else {
        collection.entities.push({
          ...item,
          name: item.name,
          entityId: item.entityId,
          capacity: item.capacity,
          entityCustomFieldData: item.entityCustomFieldData,
          entityCustomSettingsData: item.entityCustomSettingsData,
          partnerDefinedEntityId: item.partnerDefinedEntityId,
          partnerDefinedEntityName: item.partnerDefinedEntityName,
          metrics: { fuelType: item.fuelType, feedType: item.feedType },
          status: item.entityStatus
        });
      }
    });
    return Array.from(map).map(product => product[1]);
  }

  public filterSites() {
    let filtered = this.requestedSites;

    if (filtered) {
      filtered = filtered.filter(product => {
        if (
          !(
            product.siteName
              ?.toLowerCase()
              .indexOf(this.searchTerm.toLowerCase()) !== -1 ||
            (product.partnerDefinedName &&
              product.partnerDefinedName
                ?.toLowerCase()
                .indexOf(this.searchTerm.toLowerCase()) !== -1) ||
            (product.partnerDefinedId &&
              product.partnerDefinedId?.indexOf(
                this.searchTerm.toLowerCase()
              ) !== -1)
          )
        ) {
          return false;
        }
        if (
          product.entityStatus &&
          !this.statusFilter.includes(product.entityStatus)
        ) {
          return false;
        }
        return true;
      });

      this.filteredRequestedSites = this.groupBySite(filtered);
    }
  }

  public search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.filterSites();
  }

  public activateSearch() {
    this.activeSearch = true;
  }

  public deactivateSearch() {
    this.searchTerm = '';
    this.activeSearch = false;
    this.filterSites();
  }

  public siteSelected(id: number) {
    router.push(`/${this.getAppName(false)}-requests/customer/${id}`);
  }

  public rowSelected(selectedProduct: Product, index: number) {
    this.siteSelected(selectedProduct.productId);
  }
}
